.wrapper h1 {
  font-family: 'Zorque';
  color: #27c7ff;
  text-shadow: 4px 4px 0px #ff3cbe;
  font-size: 5.5rem !important;
  -webkit-text-stroke: 0.2px black;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 7rem;
  }
}
