.nounTitle {
  color: black;
}
.wrapper h1 {
  font-family: 'Zorque';
  color: #ff3cbe;
  text-shadow: 3px 3px 0px #27c7ff;
  -webkit-text-stroke: 0.2px black;
}
.nounTitle a:link {
  color: black;
}

.nounTitle a:visited {
  color: black;
}

.nounTitle a:hover {
  color: black;
}

.nounTitle:active {
  color: black;
}

.wrapper {
  display: inline-block;
}
