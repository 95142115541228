.bidHistoryWrapper {
  display: flex;
}
.bidHistory {
  margin-left: 0.5rem;
  color: var(--brand-black);
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
}

.bidHistory:hover {
  color: var(--brand-dark-red);
  text-decoration: none;
}
