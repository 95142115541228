.verifyButton {
  height: 3rem;
  color: black;
  border: transparent;
  background-color: var(--brand-black);
}

.verifyButton:hover,
.verifyButton:active,
.verifyButton:focus,
.verifyButton:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}
