.nounWrapper {
  align-self: flex-end;
  width: 100%;
}
.nounContentCol {
  display: flex;
}

.auctionActivityCol {
  padding-top: 2rem;
  align-self: start !important; /* maintains auction arrow buttons fixed from auction to auction  */
}

@media (max-width: 992px) {
  .auctionActivityCol {
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2rem;
  }
}
