.verifyButton {
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.verifyButton:hover,
.verifyButton:active,
.verifyButton:focus,
.verifyButton:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}

.verifySuccess {
  padding: 1em;
  background-color: lightgreen;
  border: 1px green solid;
  border-radius: 10px;
}

.verifyFailure {
  padding: 1em;
  background-color: lightcoral;
  border: 1px red solid;
  border-radius: 10px;
}
