.nounButton {
  height: 40px;
  background-color: grey;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI Bold';
  cursor: pointer;
  vertical-align: middle;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.nounButton:hover {
  text-decoration: none;
  background-color: white;
}

.nounButton:active {
  color: black;
}

.nounButtonContents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonIcon {
  margin-right: 5px;
  color: white;
}
