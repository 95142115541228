.auctionTitleAndNavContainer {
  display: flex;
}

@media (max-width: 992px) {
  .auctionTitleAndNavContainer h1 {
    font-size: 2.75rem;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .auctionTitleAndNavContainer {
    display: flex;
    align-items: center;
  }
}
/* Safari 7.1+ */
/* Fix safari navigation arrow display issues */
_::-webkit-full-page-media,
_:future,
:root .auctionTitleAndNavContainer button {
  padding-bottom: 4rem;
}
